.panelItem {
    padding: 1rem;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    
    main {
        height: 75%;

        img {
            height: 100%;
        }
    }

    footer {
        height: 25%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-family: 'EB Garamond', serif;
        color: dimgray;
        
        .footer-name{
            text-align: center;
            font-size: 1.2rem;
        }
        .footer-details{
            font-size: 1.7rem;
        }
    }
}