.openTime {
    width: 100%;
    text-align: center;
    font-family: 'EB Garamond', serif;

    &-caption {
        display: inline-block;
        border-bottom: 1px solid #ccc;

        h6 {
            font-size: 2.5rem;
            color: #ccc;
            letter-spacing: 2px;
        }
    }

    &-place {

        span {
            display: inline-block;
            font-size: 2.5rem;
            letter-spacing: 2px;
            color: #ccc;
            margin: 0 auto 1rem;
            padding-bottom: .5rem;
            border-bottom: 1px solid #ccc;
        }

        &-adress {

            h3,
            h6 {
                font-size: 1.6rem;
                color: #ccc;
                margin-bottom: 1rem;
                letter-spacing: 2px;
            }

            .phone-call {
                font-size: 2rem;
                color: #3c7891;
                margin-bottom: 1rem;
                letter-spacing: 2px;

                @media only screen and (max-width:1080px) {
                    font-size: 1.6rem;
                    margin-bottom: 0rem;
                    letter-spacing: 4px;
                }

                @media only screen and (max-width:650px) {
                    font-size: 1.2rem;
                    letter-spacing: 2px;
                }
            }

            @media only screen and (max-width:1400px) {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }

        &-days {
            // margin-bottom: 2rem;

            h6 {
                display: inline-block;
                font-size: 2.4rem;
                letter-spacing: 2px;
                color: #ccc;
                margin: 1rem auto;
                padding-bottom: .5rem;
                border-bottom: 1px solid #ccc;

                @media only screen and (max-width:1400px) {
                    font-size: 2rem;
                }

                @media only screen and (max-width:1080px) {
                    font-size: 1.6rem;
                    margin: 0.5rem auto;
                }

                @media only screen and (max-width:650px) {
                    font-size: 1.3rem;
                    margin: 0.5rem auto;
                }
            }

            ul {
                list-style: none;

                .days-space {
                    margin-top: 2rem;

                    @media only screen and (max-width:1080px) {
                        margin-top: .5rem;
                    }
                }

                li {
                    font-size: 1.6rem;
                    letter-spacing: 1px;
                    color: #ccc;
                }

                @media only screen and (max-width:650px) {
                    margin-bottom: 1rem;

                    li {
                        font-size: 1.2rem;
                    }
                }
            }
        }

        @media only screen and (max-width:1400px) {
            span {
                font-size: 2rem;
            }

            h3 {
                width: 50%;
                text-align: center;
            }
        }

        @media only screen and (max-width:1080px) {
            span {
                font-size: 1.6rem;
                letter-spacing: 1px;
                margin: 0 auto .5rem;
            }

            h3 {
                width: 80%;
                margin-bottom: 0.5rem;
            }
        }

        @media only screen and (max-width:650px) {
            span {
                font-size: 1.4rem;
                letter-spacing: 1px;
                margin: 0 auto;
            }

            h3 {
                font-size: 1.2rem;
            }
        }

        @media only screen and (max-width:360px) {
            border-top: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
            h3{
                font-size: 1rem;
                width: 100%;
                letter-spacing: 1px;
            }
        }
        @media only screen and (max-width:290px) {

            h3{
                font-size: .9rem;
                letter-spacing: 0px;
            }
        }


    }
}

.places-grid {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    justify-content: center;
    align-items: center;

    @media only screen and (max-width:360px) {
        grid-template-columns: max-content;
    }
}