//pos 8a emfanizetai h navigation bar
.navigation{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: .5rem;
    box-shadow: 0.2rem 0.2rem 0.5rem grey;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background: white;

    @media only screen and (max-width:900px){
        
    }
}