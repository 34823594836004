.dots {
    height: 1.5rem;
    width: 1.5rem;
    margin: 1rem;
    background-color: rgb(161, 157, 157);
    border-radius: 50%;
    cursor: pointer;
    transform: scale(1);

    transition: transform .5s ease, background-color .5s ease-in;

    &:hover {
        background-color: #8d6b52;
        transform: scale(1.2);
    }
}