.products-filter{
    grid-row: 2/-1;
    grid-column: 1/2;

    border: 1px solid #e1e1e1;
    width: 100%;
    height: 100%;
    border-radius: 5px;

    display: flex;
    flex-direction:column;
    // justify-content: space-between;

    color: dimgray;

    &-title{
        font-size: 3rem;
        font-family: serif;
        letter-spacing: 3px;
        text-align: center;
        padding: 1.5rem 0;
        border-bottom: 1px solid #e1e1e1;

        @media only screen and(max-width:1100px){
            font-size: 2.3rem;
        }
        @media only screen and(max-width:1100px){
            font-size: 2rem;
        }
        @media only screen and(max-width:400px){
            padding: .8rem 0;
        }
    }


    @media only screen and(max-width:1100px){
        grid-row:2/3;
        grid-column: 1/3;
    }
}