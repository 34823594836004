.reviewsDetails {

    width: 70%;
    margin: 0 auto;
    padding: 4rem;
    position: relative;


    border: 2px solid #93a698;
    border-radius: 30px;
    background-color: rgba(255, 255, 255, 0.3);

    &-quotes {
        height: 10rem;
        width: 10rem;
        fill: rgba(105, 105, 105, 0.1);

        position: absolute;
        top: 1rem;
        left: 1rem;
    }

    &-details {
        font-size: 3rem;
        letter-spacing: 1px;
        word-spacing: 2px;
        line-height: 2;
        color: dimgray;

        text-shadow: 1rem 1rem 0.4rem rgba(17, 17, 17, 0.2);

        @media only screen and (max-width:1100px) {
            font-size: 2.5rem;
            word-spacing: .1px;
            letter-spacing: .1px;
            text-shadow: .9rem .9rem 0.4rem rgba(17, 17, 17, 0.2);

        }

        @media only screen and (max-width:800px) {
            font-size: 2.5rem;
            line-height: 1.7;
            text-shadow: .8rem .8rem 0.4rem rgba(17, 17, 17, 0.2);

        }

        @media only screen and (max-width:600px) {
            font-size: 2rem;
            text-shadow: .7rem .7rem 0.4rem rgba(17, 17, 17, 0.2);
        }

        @media only screen and (max-width:450px) {
            font-size: 1.8rem;
            text-shadow: .5rem .5rem 0.4rem rgba(17, 17, 17, 0.2);
        }
    }

    @media only screen and (max-width:1100px) {
        width: 90%;
        padding: 2rem;
    }

    @media only screen and (max-width:500px) {
        width: 100%;
        padding: 1rem;
    }
}