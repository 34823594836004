//Einai gia to btn 
@mixin navigation-button {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    padding: .7rem;
    color: $color-navigation-text;
    background-color: $color-navigation-background;
    border: 1px solid $color-navigation-border;
    cursor: pointer;
    box-shadow: 1px 1px 3px;
    transition: background-color .3s;
}

//Einai gia ta Text sto Navigation
@mixin navigation-text {
    color: currentColor;
    font-size: 1.5rem;
    font-weight: 500;

    &::selection {
        background-color: transparent;
    }

    @media only screen and(min-width:420px)and (max-width:850px) {
        font-size: 3.5rem;
    }

    @media only screen and(min-width:320px)and (max-width:420px) {
        font-size: 2.5rem;
    }

    @media only screen and(min-width:100px)and (max-width:320px) {
        font-size: 1.8rem;
    }
}

//Einai gia ta SVG sto Navigation
@mixin navigation-icon {
    width: 100%;
    height: 2rem;
    fill: currentColor;
    z-index: 10;

    @media only screen and(min-width:100px)and (max-width:850px) {
        // width: 6rem;
        height: 6rem;
    }

    @media only screen and(min-width:100px)and (max-width:420px) {
        // width: 4rem;
        height: 4rem;
    }
}