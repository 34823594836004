.home-slider-start {
    height: 100%;
    position: relative;
    overflow: hidden;

    &-texts {
        background-image: linear-gradient(to right top,
                rgba(110, 176, 231, 0.5)30%,
                rgba(145, 152, 229, 0.6), rgba(255, 255, 255, 1));
        position: absolute;
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: left;
        display: grid;
        grid-template-columns: 40% 60%;

        @media only screen and(max-width:1300px) {
            grid-template-columns: 50% 50%;
        }

        .left-place {
            grid-column: 1/2;
            grid-row: 1/2;

            background-image: radial-gradient(circle at 0% 50%,
                    rgba(255, 255, 255, 0.5) 85%,
                    transparent 25%),
                url('https://res.cloudinary.com/karekles-gr/image/upload/v1590840646/general_images/chairs-min_mvyv6a.webp');

            background-position: left;
            background-repeat: no-repeat;
            opacity: 0.6;
            background-size: contain;

            @media only screen and(max-width:1300px) {
                grid-column: 1/3;
                grid-row: 1/2;
            }
        }

        .right-place {
            grid-column: 2/3;
            grid-row: 1/2;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .title {
                font-size: 3.5rem;
                letter-spacing: 1.5px;
                line-height: 1.2;
                color: #3d3d3d;
                text-align: center;
                width: 70%;
                padding: 2rem 0 2rem;

                @media only screen and (max-width:1500px) {
                    font-size: 3rem;
                }

                @media only screen and (max-width:1300px) {
                    font-size: 2.7rem;
                }

                @media only screen and (max-width:1000px) {
                    padding: 1rem 0;
                    font-size: 2.3rem;
                }

                @media only screen and (max-width:900px) {
                    font-size: 2rem;
                }

                @media only screen and (max-width:650px) {
                    font-size: 1.8rem;
                }

                @media only screen and (max-width:450px) {
                    font-size: 1.5rem;
                    padding: .5rem 0;
                }

            }

            .from-start {
                font-size: 2.5rem;
                letter-spacing: 2px;
                color: #3d3d3d;
                font-weight: 600;
                line-height: 0.8;
                display: flex;
                justify-content: space-evenly;
                align-items: center;

                &-year {
                    font-size: 3.5rem;
                    padding: 1rem;

                    @media only screen and (max-width:1000px) {
                        font-size: 3rem;
                    }

                    @media only screen and (max-width:650px) {
                        font-size: 2rem;
                        padding: .5rem;
                    }
                }

                @media only screen and (max-width:900px) {
                    font-size: 2rem;
                }

                @media only screen and (max-width:450px) {
                    font-size: 1.5rem;
                }
            }

            .description-anim-place {

                overflow: hidden;

                .description {
                    width: 90%;
                    margin: 0 auto;
                    line-height: 1.5;
                    letter-spacing: 2px;
                    font-size: 2rem;
                    text-align: center;
                    padding: 2rem;
                    color: #505050;
                    transform: translateY(100%);

                    @media only screen and (max-width:1500px) {
                        font-size: 1.8rem;
                    }

                    @media only screen and (max-width:1300px) {
                        width: 80%;
                    }

                    @media only screen and (max-width:1000px) {
                        font-weight: 1.6rem;
                    }

                    @media only screen and (max-width:900px) {
                        padding: 1rem;
                        font-size: 1.6rem;
                        letter-spacing: 1px;
                    }

                    @media only screen and (max-width:650px) {
                        padding: .5rem;
                        font-size: 1.5rem;
                        letter-spacing: 1px;
                    }

                    @media only screen and (max-width:500px) {
                        padding: .3rem;
                        font-size: 1.3rem;
                        letter-spacing: 1px;
                    }

                    @media only screen and (max-width:450px) {
                        width: 90%;
                        text-align: justify;
                        text-align-last: center;
                        line-height: 1.3;
                    }
                }
            }

            @media only screen and(max-width:1300px) {
                grid-column: 1/3;
                grid-row: 1/2;
                z-index: 10;
            }

            @media only screen and(max-width:450px) {
                justify-content: flex-start;
                padding: .5rem;
            }
        }
    }


    &-images {
        position: absolute;
        height: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);


    }
}


@keyframes anim-description {
    0% {
        transform: translateY(100%);
    }

    100% {
        transform: translateY(0%);
    }
}