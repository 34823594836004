.orario {

    padding: 1.5rem;

    &-title {
        font-size: 2rem;
        font-family: serif;
        color: #4d4d4d;
        letter-spacing: 2px;
        margin-bottom: 1rem;

    }

    &-times {
        font-size: 2rem;
        font-family: serif;
        font-style: italic;
        color: #4d4d4d;
        letter-spacing: 2px;

        &-and {
            font-size: 2rem;
            font-family: serif;
            color: #4d4d4d;
            letter-spacing: 2px;
            margin: 0 1rem;
        }

        &-afternoom {
            font-size: 2rem;
            font-family: serif;
            font-style: italic;
            color: #4d4d4d;
            letter-spacing: 2px;
        }
    }
}