.points-container{
    height: 2rem;
    width: 2rem;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    cursor: pointer;

    transition: background-color .3s linear;
    
    &:hover{
        background-color: #84a88d;
    }
}

.viewPoint{
    background-color: #497a56;
}