@import '../../../sass/colors/colors.scss';

.button {
    border-radius: 3px;
    border: 2px solid $color-form-login;
    margin: 1rem auto 3rem;
    padding: 1rem 3rem;
    width: 80%;
    text-align: center;
    box-shadow: 0rem 0rem 4rem transparent;
    transition: background-color .2s ease, box-shadow .2s ease;
    cursor: pointer;

    &-text {
        color: $color-form-login;
        font-size: 2.5rem;
        letter-spacing: 1px;
        transition: color .2s ease;

        &::selection {
            background-color: transparent;
        }

        @media only screen and(max-width:270px) {
            font-size: 2.2rem;
        }
        @media only screen and(max-width:250px) {
            font-size: 1.9rem;
        }
        @media only screen and(max-width:230px) {
            font-size: 1.5rem;
        }
    }

    &:hover {
        border: 2px solid $color-form-login;
        box-shadow: 0 0 4rem $color-form-login;
    }

    &:hover &-text {
        color: $color-form-login;
    }

    @media only screen and(max-width:280px) {
        padding: 1rem;
    }
}