@import '../../../sass/colors/colors.scss';


.pelatis {
    display: grid;
    grid-template-columns: max-content 1fr;
    justify-content: center;
    align-items: center;

    &-image {
        position: relative;

        &-div {
            width: 35vw;
            height: 35vw;
            border-radius: 50%;
            border: 10px solid $color-form-login;
            overflow: hidden;
            // margin:5rem 5rem 0;
            filter: blur(0px);
            transition: filter .8s ease-out;

            &:hover{
                filter: blur(5px);
            }
            &-photo {
                width: 100%;
            }
        }

        &-pre {
            display: flex;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%) translateY(50%);
        }

        @media only screen and (max-width:1100px){
            order: 2;
        }
    }

    &-photo1 {
        transform: translateX(45%) translateY(-25%);
        z-index: 10;
    }

    &-photo2 {
        transform: translateX(15%);
        z-index: 11;
    }

    &-photo3 {
        transform: translateX(-15%);
        z-index: 12;
    }

    &-photo4 {
        transform: translateX(-45%) translateY(-25%);
        z-index: 13;
    }

    &-photo1:hover,&-photo2:hover,&-photo3:hover,&-photo4:hover{
        z-index: 100;
    }
}

// einai gia to test toy image 

// width: 100%;
// height: 100%;
// position: absolute;
// top: 0;
// left: 0;
// background-image: linear-gradient(to left bottom,rgba(76, 102, 255, 0.8), white), url(http://www.webdreams.gr/zografos/src/images/pelates_photo/stoa_2.jpg);
// background-position: bottom;
// background-size: cover;
// filter: blur(4px);