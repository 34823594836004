@import '../../../../sass/main.scss';

.nav-item{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    cursor: pointer;
    height: 100%;
    @include navigation-text;
    
    transition: all .2s;

    &-icon{
        @include navigation-icon;
        transition: .4s fill .1s;
    }

    &-text{
        @include navigation-text;
        text-decoration: none;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 100;
        transition: .4s color .1s;
    }

    &::before{
        content: '';
        width: 100%;
        height: .5rem;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: $color-navitem;
        transform-origin: left;
        transform: scaleX(0);

        transition: transform .2s ;
    }
    &::after{
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: $color-navitem;
        transform-origin: bottom;
        transform: scaleY(0);
        transition: .2s transform .2s ease;
    }
    &:hover::before{
        transform: scaleX(1);
    }
    &:hover::after{
        transform: scaleY(1);
    }
    &:hover>&-text{
        color: white;
    }
    &:hover>&-icon{
        fill:white
    }

}