.circle-image-prooion {
    height: calc(50vh/4);
    // border-radius: 50%;
    background-image: radial-gradient(rgba(255, 255, 255, 0.8) 67%, rgba(255, 255, 255, 0) 71%, transparent 30%);
    width: calc(50vh/4);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;

    transition: transform 2s cubic-bezier(0.6, -0.28, 0.735, 0.045);

    .svg-for-text {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    &-img {
        height: 70%;
    }

    .circTxt {
        position: absolute;
        top: 0;
    }

    @media only screen and (max-width:1300px) {
        background-image: none;

        &-img {
            height: 100%;
        }
    }

    @media only screen and (max-width:700px) {
        height: calc(40vh/4);
        width: calc(40vh/4);
    }

}

.prooion-position {

    &-left {
        justify-self: left;
    }

    &-center {
        justify-self: center;
    }

    &-right {
        justify-self: right;
    }
}