.follow-us-container {
    width: 100%;
    // margin: 0 auto;
    text-align: center;

    .follow-us {
        display: flex;
        flex-direction: column;
    }

    .follow-us-box {
        border-bottom: 1px solid #ccc;
        padding: .5rem;

        .follow-us-text {
            font-size: 2rem;
            font-family: 'EB Garamond', serif;
            letter-spacing: 2px;
            color: #ccc;

        }

        h2 {
            font-size: 3rem;
            font-family: 'EB Garamond', serif;
            letter-spacing: 2px;
            color: #ccc;
        }


        @media only screen and (max-width:1400px) {
            .follow-us-text {
                font-size: 1.6rem;
            }

            h2 {
                font-size: 2rem;
            }
        }

        @media only screen and(max-width:1080px) {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
        }

        @media only screen and(max-width:800px) {
            flex-direction: column;
        }

        @media only screen and(max-width:650px) {
            .follow-us-text {
                font-size: 1.2rem;
            }

            h2 {
                font-size: 1.4rem;
            }
        }
    }

    .follow-us-panel {
        margin-top: 2rem;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        z-index: 2;

        @media only screen and (max-width:1350px) {
            padding-bottom: 2rem;
            border-bottom: 1px solid #ccc;
        }

        @media only screen and (max-width:1080px) {
            padding-bottom: 1rem;
            margin-top: 1rem;
        }

        @media only screen and (max-width:360px) {
            border-bottom: none;
        }
    }

    @media only screen and (max-width:800px) {
        width: 50%;

    }

    @media only screen and (max-width:360px) {
        width: 100%;

    }
}