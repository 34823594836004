.contact-images-all {

    position: relative;
    height: 100%;

    &:hover &-image:not(:hover){
        transform: scale(.8);
    }

    &-image {
        width: 25rem;
        height: 18rem;
        border: 5px solid white;
        border-radius: 10px;
        overflow: hidden;
        z-index: 1;
        transform: scale(.9);
        transition: transform .3s ease, z-index .3s ease;

        &:hover {
            z-index: 2;
            transform: scale(1.05);
        }

        img {
            width: 100%;
            height: 100%;
            border-radius: 10px;
        }
    }

    .image-place-1 {
        position: absolute;
        left: 20%;
        top: 0%;
    }

    .image-place-2 {
        position: absolute;
        right: 0%;
        top: 25%;
    }

    .image-place-3 {
        position: absolute;
        left: 0%;
        top: 32%;
    }

    .image-place-4 {
        position: absolute;
        bottom: 0;
        right: 10%;
    }


}