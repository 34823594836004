.propertiesBarPanel {
    grid-row: 3/-1;
    grid-column: 2/3;

    font-size: 1.8rem;
    font-family: serif;
    color: dimgray;
    padding: 1.5rem;
    // padding: 1.5rem 0;
    // padding-left: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    margin-bottom: 2rem;

    @media only screen and (max-width:1100px) {
        margin-top: .5rem;
    }

    @media only screen and (max-width:600px) {
        font-size: 1.5rem;
        padding: 1rem;
    }

    @media only screen and (max-width:400px) {
        padding: .5rem;
        margin-bottom: 1rem;
    }
    @media only screen and (max-width:280px) {
        font-size: 1.2rem;
    }

    &-classification {
        display: flex;
        justify-content: center;
        align-items: center;

        label {
            margin-right: 1rem;

            @media only screen and (max-width:460px) {
                display: none;
            }
        }

        select {
            font-size: inherit;
            color: inherit;
            padding: .3rem;
            cursor: pointer;
        }
    }

    &-panel {
        // display: flex;
        // justify-content: space-around;
        // align-items: center;
        // width: 30%;

        &-numItems {
            display: flex;
            justify-content: center;
            align-items: center;

            label {
                margin-right: 1rem;

                @media only screen and (max-width:460px) {
                    display: none;
                }
            }

            select {
                font-size: inherit;
                color: inherit;
                padding: .3rem;
                cursor: pointer;
            }
        }

        &-mode {
            display: flex;
            justify-content: center;
            align-items: center;

            &-btn {
                width: 3.5rem;
                height: 3.5rem;
                fill: gray;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;

                transition: all .3s ease-in;

                &-selected {
                    border: 2px solid;
                    border-radius: 5px;
                }

                &:not(:last-child) {
                    margin-right: 1rem;
                }
            }
        }
    }
}