//Gia to hover sta input
$color-input-hover:#F4B400;

//test
$color-hover-cart:#ffae00;

//Gia ta Text
$color-text-grey:#444;

//Gia tis Form
$color-form-login:#fff;

//Gia ta SVG
$color-svg-light:#cecece;

//Gia tous Browser
$color-google:#DB4437;

//Gia ta Social
$color-facebook:#4267B2;

//Gia ta Navigation Buttons
$color-navigation-background:#ccc;
//Hover
$color-navigation-background-hover:#808080;

//Gia to border
$color-navigation-border:gray;
//Gia to text
$color-navigation-text:#4c4c4c;
//Gia to icon
$color-navigation-icon:#4c4c4c;

//Gia to Navigation User Online
$color-navigation-user-online:#2eec34;

//Gia ta NavItem 
$color-navitem:#047be3;

//Gia ta cart item 
$color-title-grey:#333;
