.flag{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: help;

    &-logo{
        // transform: scale(0.22);
        z-index:10;
        height: auto;
        width: 8rem;
    }

    &-text{
        position: absolute;
        z-index: 100;
        font-size: 2.2rem;
        font-weight: bold;
        color: white;
    }
}
.rotate-svg{
    transform: rotate(180deg);
}