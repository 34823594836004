@import '../../../sass/main.scss';

.nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    &-cart {

        // margin-right: 1rem;
        @media only screen and(min-width:420px)and (max-width:850px) {
            transform: translateX(-35%);
        }

        @media only screen and(min-width:100px)and (max-width:420px) {
            transform: translateX(-10%);
        }
    }

    .small-nav-btn {
        display: none;
        width: 3.6rem;
        height: 3.6rem;

        @media only screen and(min-width:100px)and (max-width:900px) {
            display: flex;
            align-items: center;
            justify-content: center;
            order: 2;
            // margin-right: 1rem;
        }
    }

    &-items {
        display: flex;
        align-items: center;
        transform-origin: top right;
        transform: scale(1);

        transition: transform .3s ease;

        &-ul {
            height: 100%;
            width: 100%;
            list-style: none;
            display: flex;
            color: $color-text-grey;

            @media only screen and (max-width:900px) {
                height: 80%;
            }
        }

        @media only screen and(min-width:100px)and (max-width:900px) {
            position: absolute;
            top: 3.6rem;
            background-color: white;

            left: 0;
            width: 100vw;
            height: calc(100vh - 3.6rem);

            ul {
                flex-direction: column;
            }
        }

    }
}

.account-sing-in,
.account-sing-out {
    cursor: pointer;
}