@import '../../../sass/colors/colors.scss';

//ti 8a emfanizete kai pos sto hover tou cartItem
.cartHover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
    display: grid;
    
    grid-template-rows: max-content 1fr max-content;
    
    cursor: pointer;
    z-index: 200;

    &-top {
        display: flex;
        justify-content: flex-end;
        margin: .5rem;
        z-index: 120;
    }

    &-main {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}