.phone {
    &-title {
        font-size: 2rem;
        font-family: serif;
        color: #4d4d4d;
        letter-spacing: 2px;
    }

    &-number {
        font-weight: 500;
        font-family: fantasy;
        font-size: 2rem;
        letter-spacing: 5px;
        color: #045404;
        text-decoration: none;
    }

    
    @media only screen and(max-width:900px) {
        &-number{
            font-size: 1.8rem;
        }
    }
}

.phones-panel{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}