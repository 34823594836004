#carousel {
    position: relative;
    height: 100%;
    width: 100%;
    padding: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.noselect {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.panel-items{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
}
