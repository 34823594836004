.circle-img {

    .circle-contact {
        height: 8vw;
        width: 8vw;
        fill: dimgray;
        border-radius: 50%;
        border: 3px solid dimgray;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        transition: background .3s ease;

        &:hover {
            background-color: rgba(105, 105, 105, 0.8);

            &>.circle-contact-img {
                fill: white;
                height: 4.5vw;
                width: 4.5vw;
                stroke: white;
            }
        }

        &-img {
            height: 50%;
            width: 50%;
            fill: dimgray;
            stroke: transparent;
            transition: stroke .2s linear, fill .2s linear, height .2s ease, width .2s ease;
        }

        @media only screen and (max-width:1000px) {
            height: 8rem;
            width: 8rem;
        }

        @media only screen and (max-width:400px) {
            height: 6rem;
            width: 6rem;
        }

        @media only screen and (max-width:300px) {
            height: 5rem;
            width: 5rem;
        }
    }
}