.person {
    margin-top: 10rem;
    display: flex;
    //
    justify-content: flex-start;
    align-items: center;

    &-photo {
        margin-right: 1rem;

        &-img {
            width: 4rem;
            height: 4rem;
            border-radius: 50%;
        }
    }

    &-name {
        font-size: 2.3rem;
        letter-spacing: 1.5px;
        color: #444040;
        text-transform: uppercase;
        text-shadow: 1rem 1rem 0.4rem rgba(0, 0, 0, 0.3);

        @media only screen and (max-width:1100px) {
            font-size: 2rem;
            text-shadow: .9rem .9rem 0.4rem rgba(0, 0, 0, 0.3);
        }

        @media only screen and (max-width:800px) {
            font-size: 1.7rem;
            text-shadow: .7rem .7rem 0.4rem rgba(0, 0, 0, 0.3);
        }

        @media only screen and (max-width:500px) {
            font-size: 1.2rem;
            text-shadow: 0.5rem 0.5rem 0.4rem rgba(0, 0, 0, 0.3);
        }
    }

    @media only screen and (max-width:900px) {
        margin-top: 5rem;
    }

    @media only screen and (max-width:500px) {
        margin-top: 2rem;
    }
}