@import '../../sass/main.scss';

$padding-form:2rem;

//Ta btns gia sing in
.btn-google{
    grid-row: 1/2;
    grid-column: 2/3;
}


.conect{
    width: 70%; //kanonika einai 100% ean einai mesa se grid
    margin: 0 auto ;//oxi an baloyme grid

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background-color: $color-form-login;
    padding: $padding-form;

    // border: 1px solid grey;

    &-buttons{
        width: 100%;
        display: grid;
        
        //otan mpoyn alla btn sing in (facebook,twiter,....) prepei na allaksei
        grid-template-columns:10rem minmax(max-content,1fr) 10rem;
        grid-column-gap: $padding-form;
    }

    &-form{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.other{
    margin: 1.5rem 0;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    &-hr{
        width: 35%;
        height: 5px;
        border-bottom: 2px solid $color-text-grey;
    }
    &-text{
        font-size: 1.5rem;
        font-weight: bold;
        color: $color-text-grey;
    }
}