.home-slider-prooionta {
    height: 100%;
    background-image: linear-gradient(to right top,
            rgba(110, 176, 231, 0.5)30%,
            rgba(145, 152, 229, 0.6), rgba(255, 255, 255, 1));

    .prooionta-grid {
        height: 100%;
        display: grid;
        grid-template-columns: 30% 40% 30%;

        &-left {
            display: grid;
            grid-template-rows: repeat(4, 1fr);
            height: 100%;
            grid-gap: 1rem;
            padding: 1rem;
            align-items: center;

            &-1,
            &-3 {
                justify-self: right;
            }

            &-2,
            &-4 {
                justify-self: left;
            }

            @media only screen and (max-width: 700px) {
                grid-row: 2/3;
                grid-column: 1/5;
                grid-template-rows: auto;
                grid-template-columns: repeat(4, 1fr);
            }

            @media only screen and (max-width: 380px) {
                grid-gap: 0rem;
                padding: .5rem;
            }
        }

        &-center {
            background-image: radial-gradient(rgba(255, 255, 255, 0.8) 70%,
                    rgba(255, 255, 255, 0) 71%,
                    transparent 30%);
            height: 100%;
            width: 100%;
            margin: 0 auto;
            overflow: hidden;
            backface-visibility: hidden;
            position: relative;

            display: flex;
            justify-content: center;
            align-items: center;


            .center-details {
                height: 100%;
                width: 100%;
                position: relative;

                &-text {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    p {
                        width: 80%;
                        font-size: 1.7rem;
                        letter-spacing: 1px;
                        line-height: 1.5;
                        text-align: center;
                        margin-bottom: 1rem;

                        @media only screen and (max-width:1200px) {
                            margin-bottom: .5rem;
                        }

                        @media only screen and (max-width:700px) {
                            font-size: 1.3rem;
                        }

                        @media only screen and (max-width:500px) {
                            font-size: 1.2rem;
                        }

                        @media only screen and (max-width:400px) {
                            width: 90%;
                            text-align: justify;
                            text-align-last: center;
                        }
                    }

                    .products {
                        transform: scale(0);
                        opacity: 0;

                        transition: opacity 2s cubic-bezier(0.6, -0.28, 0.735, 0.045),
                            transform 2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
                    }

                    &-red {
                        color: red;
                        font-weight: 600;
                        animation: anim-text-years 2s ease infinite;
                    }

                    ul {

                        list-style: none;
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        grid-gap: .7rem;
                        grid-column-gap: 1.5rem;

                        transform: scale(1);

                        transition: transform 2s cubic-bezier(0.6, -0.28, 0.735, 0.045);

                        li {
                            padding: .5rem;
                            font-size: 1.3rem;
                            text-align: center;
                            opacity: 0;
                            transform: scale(0);

                            transition: opacity 2s cubic-bezier(0.6, -0.28, 0.735, 0.045),
                                transform 2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
                        }

                        @media only screen and (max-width:1200px) {
                            grid-gap: 0;
                            grid-column-gap: 0;
                        }

                        @media only screen and (max-width:1100px) {

                            li {
                                font-size: 1.1rem;
                            }
                        }

                        @media only screen and (max-width:500px) {

                            li {
                                padding: .1rem;

                                h2 {
                                    font-weight: 400;
                                }
                            }
                        }

                        @media only screen and (max-width:380px) {
                            li {
                                padding: 0rem;
                            }
                        }
                    }

                }


            }


            @media only screen and (max-width:1300px) {
                background-image: radial-gradient(rgba(255, 255, 255, 0.8) 30%, rgba(255, 255, 255, 0) 67%, transparent 17%);
            }

            @media only screen and (max-width:700px) {
                grid-row: 1/2;
                grid-column: 1/5;
            }
        }

        &-right {
            display: grid;
            grid-template-rows: repeat(4, 1fr);
            height: 100%;
            grid-gap: 1rem;
            padding: 1rem;
            align-items: center;

            &-1,
            &-3 {
                justify-self: left;
            }

            &-2,
            &-4 {
                justify-self: right;
            }

            background-image: radial-gradient(rgba(255, 255, 255, 0.8) 30%, rgba(255, 255, 255, 0) 67%, transparent 17%);

            @media only screen and (max-width: 700px) {
                grid-row: 3/4;
                grid-column: 1/5;
                grid-template-rows: auto;
                grid-template-columns: repeat(4, 1fr);
                transform: translateY(-40%);
            }

            @media only screen and (max-width: 380px) {
                grid-gap: 0rem;
                padding: .5rem;
            }

        }

        @media only screen and (max-width:1000px) {
            grid-template-columns: 27% 45% 27%;
        }

        @media only screen and (max-width:800px) {
            grid-template-columns: 20% 1fr 20%;
        }

        @media only screen and (max-width:700px) {
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: repeat(3, max-content);
            padding: 2rem;
        }

        @media only screen and (max-width:400px) {
            padding: 1rem;
        }

        @media only screen and (max-width:380px) {
            padding: .1rem;
        }
    }

}

@keyframes anim-text-years {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.4);
    }

    100% {
        transform: scale(1);
    }
}