@import '../../sass/main.scss';
.input-element{
    width: 100%;
}

.form-input{
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 2px;
    background-color: grey;
    padding: .2rem;
    margin: 1rem 0;
    &-svg{
        width: 2.5rem;
        height: 2.5rem;
        padding: .1rem;
        fill:$color-svg-light;
    }

    [type='password']{
        letter-spacing: 2px;
        font-size: 2.2rem;
    }

    &-text{
        font-size: 2rem;
        color: $color-text-grey;
        background-color: white;
        width: 100%;
        border: none;
        padding: .3rem 1rem;
    }

    &:hover , &:focus{
        box-shadow: 1px 3px 15px $color-input-hover,-1px -3px 15px $color-input-hover;
    }
}

.label-dangerous{
    color: red;
}