.footer {
    position: relative;
    height: 100%;
    // padding: 2rem;
    background-image: linear-gradient(to bottom, rgba(128, 128, 128, 0.52), rgba(18, 18, 18, 0.73)), url('../../assets/images/globalMap.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-blend-mode: multiply;

    display: grid;
    grid-template-rows: max-content;
    grid-auto-rows: max-content;
    align-items: center;

    background-color: #333;


    &-hr {
        width: 70%;
        margin: 0 auto;
        border-color: rgba(204, 204, 204, .5);

        @media only screen and(max-width:800px) {
            display: none;
        }
    }

    &-main {
        padding: 0rem 0 1rem;
        display: flex;
        justify-content: center;
        align-items: center;

        .site-name {
            font-size: 6rem;
            color: rgba(87, 87, 87, 0.5);
            letter-spacing: 30px;
            cursor: default;
            z-index: 0;
            
            &::selection {
                user-select: none;
                background-color: transparent;
            }
        }

        @media only screen and(max-width:1080px) {
            padding: 0 0 .5rem;

            .site-name {
                font-size: 4rem;
                letter-spacing: 20px;
            }
        }

        @media only screen and(max-width:1080px) {
            display: none;
        }

        @media only screen and(max-width:700px) {
            padding: 0;

            .site-name {
                font-size: 3.5rem;
                letter-spacing: 10px;
            }
        }
    }

    &-footer {
        padding: 0rem;
        display: grid;
        grid-template-columns: repeat(2, max-content);
        grid-template-rows: max-content;
        grid-row-gap: 2rem;
        justify-content: space-around;
        align-items: center;

        @media only screen and (max-width:1350px) {
            grid-template-columns: none;
            grid-template-rows: max-content;
        }

        @media only screen and (max-width:800px) {
            justify-items: center;
        }

        @media only screen and (max-width:350px) {
            grid-row-gap: 0;
        }
    }

    .create-by {
        border-top: 2px solid rgba(204, 204, 204, 0.5);
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1.5rem;

        &-txt {
            font-size: 1.5rem;
            font-weight: 100;
            letter-spacing: 1.5px;
            color: #ccc;
        }

        h2 {
            font-style: italic;
            margin-left: .5rem;
            display: inline-block;
            font-size: 1.5rem;
            font-weight: 400;
            letter-spacing: 1.5px;
            color: #ccc;
            border-bottom: 1px solid;
        }

        @media only screen and(max-width:800px) {
            padding: .5rem;

            h4 {
                font-size: 1.2rem;
            }

            h2 {
                font-size: 1.2rem;
            }
        }

        @media only screen and(max-width:650px) {
            padding: .5rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            h4 {
                text-align: center;
                margin-bottom: .3rem;
            }

            h2 {
                font-size: 1.2rem;
            }
        }

        @media only screen and(max-width:360px) {
            border-top: none;
        }

    }

}