.home-slider-creation {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom left,
            rgba(2, 138, 148, 0.7),
            rgba(2, 169, 31, 0.5)),
        url('https://res.cloudinary.com/karekles-gr/image/upload/v1590517375/general_images/home-slider/slider-creation/zografos-sketch-image-4_agkzjw.webp');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    overflow: hidden;

    display: grid;
    grid-template-columns: 35% 65%;
    grid-template-rows: 100%;


    .baner-creation {
        height: 100%;
        background-image: radial-gradient(circle at 0% 50%, rgba(255, 255, 255, 0.6) 80%, transparent 20%);
        display: inline-block;
        transform: translate(-110%, 0);
        // animation: animation-image 1.5s ease-in;

        &::before {
            content: '';
            display: block;
            width: 70%;
            height: 20%;
            position: absolute;
            bottom: 4%;
            left: 0;
            background: rgba(0, 0, 0, 0.5);
            border-radius: 50%;
            filter: blur(4rem);
        }

        &-image {
            height: 100%;
            transform: translate(40%, 0px);

            @media only screen and (max-width:1100px) {
                transform: translate(30%, 0px);
            }

            @media only screen and (max-width:1000px) {
                transform: translate(20%, 0px);
            }

            @media only screen and (max-width:1000px) {
                transform: translate(10%, 0px);
            }

            @media only screen and (max-width:1000px) {
                transform: translate(-10%, 0px);
            }
        }

        @media only screen and (max-width:800px) {
            background-image: radial-gradient(circle at -25% 50%, rgba(255, 255, 255, 0.6) 60%, transparent 20%);
        }

        @media only screen and (max-width:600px) {
            grid-row: 1/2;
            grid-column: 1/2;
        }


    }


    .baner-slider-text {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        transform: translateX(100%);
        animation: anim-text 1.5s ease-in 0s;

        &-header {
            padding: 1rem;
            border-bottom: 3px solid currentColor;

            h2 {
                font-size: 3rem;
                letter-spacing: 5px;
                color: currentColor;

            }

            @media only screen and (max-width:800px) {
                padding: .5rem;

                h2 {
                    font-size: 2.5rem;
                }
            }

            @media only screen and (max-width:450px) {
                h2 {
                    font-size: 1.5rem;
                }
            }
        }

        &-main,
        &-footer {
            margin-top: 5rem;
            width: 75%;
            text-align: center;

            p,
            h2 {
                display: inline;
                font-size: 2rem;
                color: currentColor;
                letter-spacing: 1.5px;
                word-spacing: 2px;
                line-height: 2;
            }

            h2 {
                text-decoration: underline;
                margin: 0 1rem;
            }

            .sub-title {
                display: block;
            }

            @media only screen and (max-width:1300px) {
                margin-top: 4rem;

                p,
                h2 {
                    font-size: 1.8rem;
                }
            }

            @media only screen and (max-width:1200px) {
                margin-top: 2rem;
                width: 80%;

                p,
                h2 {
                    font-size: 1.7rem;
                }
            }

            @media only screen and (max-width:1000px) {
                margin-top: 1.7rem;
                width: 85%;

                p,
                h2 {
                    font-size: 1.6rem;
                }
            }

            @media only screen and (max-width:800px) {
                margin-top: 1rem;
            }

            @media only screen and (max-width:700px) {

                p,
                h2 {
                    line-height: 1.5;
                }
            }

            @media only screen and (max-width:450px) {

                p,
                h2 {
                    font-size: 1.2rem;
                    line-height: 1.3;
                    text-align: justify;
                    text-align-last: center;
                }
            }
        }



        @media only screen and (max-width:600px) {
            grid-row: 1/2;
            grid-column: 1/2;
            backdrop-filter: brightness(0.5);
        }
    }


    @media only screen and (max-width:600px) {
        grid-template-columns: 100%;
    }


}

@keyframes animation-image {
    0% {
        transform: translate(-110%, 0);
        opacity: 0;
    }

    99% {
        opacity: 1;
    }

    100% {
        transform: translate(0%, 0);
    }
}

@keyframes animation-text {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }

    99% {
        opacity: 1;
    }

    100% {
        transform: translateX(0%);
    }
}