.factor-details {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    padding: 2rem;

    @media only screen and (max-width:1300px) {
        width: 90%;
        padding: 1rem;
    }

    @media only screen and (max-width:1300px) {
        width: 100%;
        padding: 1rem;
    }
}

.factor-text {
    font-size: 3rem;
    letter-spacing: 1px;
    word-break: break-word;
    word-spacing: 2px;
    color: dimgray;
    line-height: 2;

    animation: animText 1s 1s ease-in;

    transition: transform 2s ease;

    @media only screen and (max-width:1300px) {
        font-size: 2rem;
    }

    @media only screen and (max-width:700px) {
        font-size: 1.7rem;
    }

    @media only screen and (max-width:550px) {
        font-size: 1.4rem;
        letter-spacing: 0.5px;
        word-break: normal;
        word-spacing: 0.5px;
        line-height: 1.5;
    }

    @media only screen and (max-width:380px) {
        font-size: 1.2rem;
    }

}

@keyframes animText {
    0% {
        transform: scale(.2);
    }

    100% {
        transform: scale(1);
    }
}