@import '../../../sass/colors/colors.scss';

.cart-category {
    border-radius: 1rem;
    border: 3px solid transparent;
    transition: border .2s ease;

    &:hover {
        border: 3px solid #ffae00;
    }

    &:hover .cart-panel-main-logo {
        transform: scale(1.5);
        transition: transform 3s linear;
    }

    .cart-panel {
        display: flex;

        flex-direction: column;
        align-items: center;
        justify-content: center;

        padding: .5rem;

        position: relative;

        background-color: transparent;

        cursor: pointer;
        overflow: hidden;
        border-radius: 10px;

        transition: border .2s ease, background-color .3s linear;

        &-main {

            &-logo {
                height: 25rem;
                width: 100%;
                display: flex;
                justify-content: center;
                margin: 0 auto 2rem;
                transform: scale(1);
                transition: transform .3s linear;

                &--img {
                    height: 100%;
                    width: auto;
                }

                @media only screen and (max-width:400px) {
                    height: 22rem;
                }
                @media only screen and (max-width:380px) {
                    height: 20rem;
                }
                @media only screen and (max-width:260px) {
                    height: 15rem;
                }
            }

            &-details {
                &--text {}
            }
        }

        &-footer {
            width: 100%;
            text-align: center;
            background-color: #ffae00;
            border-top-right-radius: 1rem;
            border-bottom-left-radius: 1rem;
            z-index: 10;

            &--text {
                font-size: 2.5rem;
                font-weight: bold;
                letter-spacing: 3px;
                margin: 1rem 2rem;
                text-transform: uppercase;

                @media only screen and (max-width:380px) {
                    font-size: 1.9rem;
                    letter-spacing: 1px;
                    margin: 1rem;
                }

                @media only screen and (max-width:300px) {
                    font-size: 1.7rem;
                    letter-spacing: normal;
                    margin: .5rem;
                }
            }
        }

    }
}