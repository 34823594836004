.address-container {
    padding: 1rem 0;

    grid-row: 1/2;
    grid-column: 1/-1;
    justify-self: flex-start;

    &-panel {
        list-style: none;

        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 5rem;

        font-size: 2rem;
        color: dimgray;

        &-li {
            &-link {
                position: relative;
                text-decoration: none;
                color: dimgray;

                &:hover {
                    color: #303030
                }
            }

            &-home {
                position: absolute;
                width: 2rem;
                height: 2rem;
                left: 0;
                top: 50%;
                transform: translate(-100%, -50%);
                fill: dimgray;

                &:hover {
                    fill: #303030;
                }
            }

            &:not(:last-child) {
                &::after {
                    content: ">";
                    display: inline-block;
                    margin-left: .3rem;
                    color: dimgray;
                }

                ;

                margin-right: .3rem;
            }
        }

    }
}