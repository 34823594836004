@import '../../../sass/main.scss';

//ola gia to details toy modeloy
.details {
    color: dimgray;

    &-title {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: .5rem 0;

        &-text {
            text-align: center;
            font-size: 2.5rem;
            color: #333;

            @media only screen and (max-width:270px) {
                font-size: 2.3rem;
            }

            @media only screen and (max-width:230px) {
                font-size: 1.5rem;
            }
        }
    }

    //einai gia na diaxorizei ton titlo apo to ypoloipo 
    &-hr {
        width: 90%;
        margin: 0 auto;
        border-color: $color-navigation-border;
    }

    //einai gia thn timh
    &-price {
        display: flex;
        justify-content: center;
        align-items: center;

        &-text {
            font-size: 1.8rem;
        }

        &-num {
            margin-left: 2rem;
            font-size: 2.5rem;
            font-weight: bold;
            color: $color-google;
        }
    }

    //einai gia ton fpa
    &-fpa {
        text-align: center;
        font-size: 1.3rem;
        margin-bottom: .5rem;

        &-text {
            color: #4c4c4c;
        }
    }

    //to onoma kai to code gia to modelo
    &-model {
        display: flex;
        justify-content: space-around;
        text-align: center;

        &-text {
            font-size: 1.3rem;
            color: #333;

            &-right {
                font-size: 1.3rem;
                color: #444;
                margin-left: .5rem;
            }
        }

        div {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }

        @media only screen and (max-width: 280px) {
            div {
                flex-direction: column;
            }

            &-text {
                &-right {
                    margin-left: 0;
                }
            }
        }
    }
}