.logo{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-name{
        margin: 0 3rem;
    }
    &-text{
        margin:0 0 .4rem;
    }
}