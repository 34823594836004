.footer-categories-panel {
    padding: 2rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 2rem;
    justify-items: center;
    align-items: center;
    grid-template-rows: 7rem;
    grid-auto-rows: 7rem;

    @media only screen and (max-width:1500px) {
        padding: 0;
    }

    @media only screen and (max-width:1400px) {
        grid-row-gap: 0rem;
        grid-template-rows: 5rem;
        grid-auto-rows: 5rem;
    }

    @media only screen and (max-width:1080px) {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 4rem;
        grid-auto-rows: 4rem;
    }

    @media only screen and(max-width:800px) {
        grid-template-rows: 4rem;
        grid-auto-rows: 4rem;
    }

    @media only screen and(max-width:600px) {
        display: none;
    }
}