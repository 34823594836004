@import '../../sass/colors/colors.scss';

@mixin transition {
    transition: background-color .3s, color .3s, box-shadow .3s;
}

.custom-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 3rem;
    margin-top: 1rem;
    border-radius: 4px;
    border: none;
    font-size: 2.5rem;
    font-weight: bold;
    letter-spacing: 1px;
    backface-visibility: hidden;
    box-shadow: 0px 0px 0px white;
    //Einai gia to anim
    @include transition();

    &-google {
        //Einai gia to anim
        background-color: white;
        color: $color-google;
        border: 1px solid $color-google;
        @include transition();
        
        &:hover {
            // background-color: $color-google;
            // color: white;
            box-shadow: 1px 3px 15px $color-google, -1px -3px 15px $color-google;
        }
    }

    &-facebook {
        background-color: $color-facebook;
        color: white;
        //Einai gia to anim
        @include transition();

        &:hover {
            background-color: white;
            color: $color-facebook;
            box-shadow: 1px 3px 15px $color-facebook, -1px -3px 15px $color-facebook;
        }
    }

    &-black {
        background-color: black;
        color: white;
        //Einai gia to anim
        @include transition();

        &:hover {
            background-color: white;
            color: $color-text-grey;
            box-shadow: 1px 3px 15px $color-text-grey, -1px -3px 15px $color-text-grey;
        }
    }

    &:focus {
        outline: none;
    }
}

.google-logo,
.facebook-logo,
.new-write {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1rem;
    fill: currentColor;
}