// color - blue : #1596d3

.contact-form {
    position: relative;

    font-family: 'Roboto', 'sans-serif', '-apple-system', 'BlinkMacSystemFont', "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue";

    &-mask {
        height: 70rem;
        background: #9ee6b2;
        clip-path: polygon(30% 0, 100% 0, 70% 100%, 0% 100%);

        @media only screen and (max-width:500px) {
            clip-path: polygon(20% 0, 100% 0, 80% 100%, 0% 100%);
        }
    }

    &-inputs {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 60%;
        height: 80%;
        background-color: #d3b215;
        border-bottom-right-radius: 10rem;
        border-top-left-radius: 10rem;

        border: 1rem solid white;

        display: grid;
        grid-template-rows: max-content 1fr;
        align-items: center;

        grid-template-columns: 1fr 60% 1fr;
        // height: 100%;

        @media only screen and (max-width:1100px) {
            grid-template-columns: 1fr 70% 1fr;
            width: 70%;
        }

        @media only screen and (max-width:900px) {
            grid-template-columns: 1fr 80% 1fr;
            width: 75%;
        }

        @media only screen and (max-width:600px) {
            width: 90%;
        }

        @media only screen and (max-width:600px) {
            border-bottom-right-radius: 7rem;
            border-top-left-radius: 7rem;
            border: 0.8rem solid white;
        }

        @media only screen and (max-width:350px) {
            width: 100%;
            border: 0.3rem solid white;
            height: 70%;
            grid-template-columns: 1fr 90% 1fr;
            border-bottom-right-radius: 1rem;
            border-top-left-radius: 1rem;
        }

        &-title {
            grid-column: 1/4;
            grid-row: 1/2;

            font-size: 3rem;
            color: white;
            justify-self: center;
            align-self: center;
            padding: 1rem;

            @media only screen and (max-width:500px) {
                font-size: 2.8rem;
            }

            @media only screen and (max-width:400px) {
                font-size: 2.2rem;
            }
        }

        &-form {
            grid-column: 2/3;
            grid-row: 2/-1;

            .form-text {
                background-color: white;
                border-radius: 10px;
                // border: 1px solid #9c9c9c;
                padding: .7rem;

                position: relative;

                margin-bottom: 2.5rem;

                &-input {
                    font-size: 2rem;
                    border: none;
                    width: 100%;
                    color: #d3b215;

                    &:focus {
                        outline: none;
                    }

                }

                &-label {
                    position: absolute;
                    top: 100%;
                    left: 5px;
                    font-size: 1.5rem;
                    color: white;
                    font-family: arial;

                    transition: all .3s ease;
                }

                @media only screen and (max-width:400px) {

                    &-label,
                    &-input {
                        font-size: 1.6rem;
                    }
                }

            }

            .form-text-input:placeholder-shown+.form-text-label {
                opacity: 0;
                visibility: hidden;
                top: 0;
                font-size: 2rem;
            }

            .form-btn {
                cursor: pointer;
                background-color: #ffffff;
                color: #98800f;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 5px;

                transition: background-color .3s ease-in;

                &-input {
                    width: 100%;
                    height: 100%;
                    padding: 1rem;
                    cursor: pointer;
                    background-color: transparent;
                    border: none;
                    font-size: 2rem;
                    letter-spacing: 2px;
                    font-weight: 600;
                    text-transform: uppercase;
                    text-align: center;
                    transition: color .3s ease-in;

                    &:focus {
                        outline: none;
                    }
                }

                &:hover {
                    background-color: #98800f;

                    .form-btn-input {
                        color: #fff;
                    }
                }
            }
        }
    }


}