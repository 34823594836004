/* //setting for all project */
html{
    font-size: 62.5%; 
    /* 62.5% = 10px = 1rem */
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.top-space {
    height: 4rem;
}