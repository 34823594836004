.company-panel {
    font-family: 'EB Garamond', serif;
    color: #4e4e4e;


    &-title {
        font-size: 5rem;
        text-align: center;
        letter-spacing: 10px;
        text-transform: uppercase;
        color: #736621;
        padding: 1rem;
        border-bottom: 1px solid;
        border-top: 1px solid;
        display: inline-block;
        margin-bottom: 2rem;

        @media only screen and(max-width:500px) {
            font-size: 4rem;
        }

        @media only screen and(max-width:360px) {
            font-size: 2.5rem;
        }

        @media only screen and(max-width:260px) {
            font-size: 1.8rem;
        }
    }

    &-details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &-title {
            margin: 3rem auto;
            text-align: center;
            font-size: 4rem;
            letter-spacing: 2px;

            @media only screen and(max-width:1080px) {
                font-size: 3.3rem;
            }

            @media only screen and(max-width:700px) {
                font-size: 4rem;
            }

            @media only screen and(max-width:500px) {
                font-size: 3rem;
            }

            @media only screen and(max-width:360px) {
                font-size: 2.2rem;
            }

        }

        &-text {
            width: 70%;
            font-family: serif;
            font-size: 2.6rem;
            line-height: 1.8;
            letter-spacing: 2px;
            word-spacing: 2px;
            text-align: center;

            @media only screen and(max-width:1200px) {
                width: 90%;
            }

            @media only screen and(max-width:700px) {
                font-size: 2.1rem;
            }

            @media only screen and(max-width:500px) {
                line-height: 1.5;
                letter-spacing: 1px;
                word-spacing: 1px;
                text-align: justify;
                font-size: 2rem;
            }

            @media only screen and(max-width:400px) {
                font-size: 1.7rem;
            }
        }
    }

    &-history {
        display: grid;
        grid-template-rows: repeat(8, 100px);
        grid-template-columns: repeat(7, 1fr);
        width: 60%;
        margin: 0 auto 10rem;

        &-title {
            // grid-row: 1/2;
            // grid-column: 1/-1;
            margin-top: 7rem;
            display: flex;
            justify-content: center;
            align-items: center;

            &-text {
                font-size: 3.5rem;
                letter-spacing: 3px;
                font-style: italic;
                border-top: 1px solid;
                border-bottom: 1px solid;
                padding: 0.5rem;
                color: #736621;
                align-self: start;

                // margin-bottom: 4rem;
                @media only screen and(max-width:1200px) {
                    font-size: 3rem;
                    letter-spacing: 1.5px;
                }

                @media only screen and(max-width:1080px) {
                    font-size: 2.3rem;
                }

                @media only screen and(max-width:700px) {
                    width: 70%;
                    text-align: center;
                    margin: 0 auto;
                }

                @media only screen and(max-width:260px) {
                    font-size: 1.8rem;
                    width: 80%;
                }
            }

            @media only screen and(max-width:500px) {
                margin-top: 3rem;
            }
        }


        @media only screen and (max-width:1500px) {
            width: 70%;
        }

        @media only screen and(max-width:1200px) {
            width: 80%;
        }

        @media only screen and(max-width:600px) {
            width: 90%;
        }

        @media only screen and(max-width:500px) {
            grid-template-rows: repeat(1, 100px);
            margin-top: 3rem;
        }
    }

    &-owners {
        &-1 {
            grid-row: 2/5;
            grid-column: 1/-1;

            @media only screen and(max-width:500px) {
                grid-row: 1/4;
            }
        }

        &-2 {
            grid-row: 4/7;
            grid-column: 1/-1;
        }

        &-3 {
            grid-row: 6/9;
            grid-column: 1/-1;

            @media only screen and(max-width:500px) {
                grid-row: 7/10;
            }
        }

        display: flex;
        align-items: center;

        &-left {
            justify-content: flex-start;
        }

        &-right {
            justify-content: flex-end;
        }


        &-person {
            font-size: 2.5rem;
            color: #736621;
            letter-spacing: 2px;

            &.person-left {
                order: 2;
                margin-left: 5rem;
            }

            ;

            &.person-right {
                order: 1;
                margin-right: 5rem;
            }

            @media only screen and (max-width:700px) {
                font-size: 2rem;
            }

            @media only screen and (max-width:500px) {
                font-size: 1.8rem;
            }

            @media only screen and (max-width:400px) {
                // margin: 2rem auto 0;

                &.person-left {
                    margin: 2rem auto 0;
                    width: 70%;
                    text-align: center;
                }

                &.person-rigth {
                    order: 3;
                    margin: 2rem auto 0;
                    width: 70%;
                    text-align: center;
                }
            }
        }

        &-photo {
            width: 20%;
            border: 5px solid rgba(105, 105, 105, 0.5);
            border-radius: 5%;
            overflow: hidden;

            &-image {
                width: 100%;
            }

            @media only screen and (max-width:1500px) {
                width: 23%;
            }

            @media only screen and (max-width:700px) {
                width: 30%;
            }

            @media only screen and (max-width:700px) {
                width: 40%;
            }

            @media only screen and (max-width:400px) {

                width: 70%;
            }
        }


        @media only screen and(max-width:400px) {
            flex-direction: column;
            margin-top: 2rem;
        }
    }
}