.news-panel{
    min-height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    

    &-text{
        font-size: 2vw;
        text-align: center;
        letter-spacing: 2px;
        color: dimgray;

        @media only screen and (max-width:1000px){
            font-size: 3.5vw;
        }
    }
}