.review-panel {
    height: 100%;
    padding: 2rem;
    position: relative;
    // text-align: center;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    transition: height .2s ease;

    @media only screen and (max-width:360px) {
        padding: 1rem;
    }
}

.reviews-num {
    margin: 5rem auto 0;
    width: 40%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    @media only screen and (max-width:600px) {
        width: 80%;
    }

    @media only screen and (max-width:400px) {
        width: 90%;
    }
}