@import '../../sass/main.scss';

.home {
    &-slider {
        // height: 90vh;
        // background-color: $color-navitem;
    }

    &-newProduct {
        height: 35rem;
        background-color: $color-navigation-background-hover;
        position: relative;
    }

    &-category {
        display: grid;
        grid-template-columns: 1fr 80% 1fr;
        padding: 5rem;

        &-grid {
            grid-column: 2;

            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(33rem, 1fr));
            grid-row-gap: 5rem;
            grid-column-gap: 10rem;
        }

        @media only screen and (max-width:600px) {
            padding: 2rem 0;
        }

        @media only screen and (max-width:450px) {
            grid-template-columns: 1fr 100% 1fr;
        }

        @media only screen and (max-width:400px) {
            grid-template-columns: 100%;

            &-grid {
                grid-column: 1;
                grid-template-columns: repeat(auto-fit, minmax(28rem, 1fr));
            }
        }

        @media only screen and (max-width:350px) {

            &-grid {
                grid-column: 1;
                grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
            }
        }
    }

    &-contact {
        height: 70vh;
    }

    &-pelates {
        // height: 70vh;
        // padding: 2rem 5rem 12rem;
        // position: relative;
    }

    &-reviews {
        padding: 5rem;
        background-color: #c1ddc8;
        min-height: 80vh;

        @media only screen and (max-width:1400px) {
            min-height: 70vh;
        }

        @media only screen and (max-width:1100px) {
            min-height: 50vh;
            padding: 1rem;
        }

        @media only screen and (max-width:400px) {
            padding: 0rem;
        }
    }

    &-footer {
        // height: 60vh;
        background-color: $color-title-grey;
    }
}