.cartItem {
    // margin: 0 auto;
    // display: block;
    // width: 30rem;
    // min-width: 100%;
    // height: 45rem;
    // min-height: 100%;
    // //gia to test
    // border:.5px solid lightgrey;
    // cursor: pointer;
    // border-radius: .7rem;
    // position: relative;
    // overflow: hidden;
    // color:rgba(231, 220, 186, 0.5);
    padding: .5rem;
    display: block;
    max-width: 30rem;
    width: 100%;
    min-width: 18rem;
    height: 100%;
    min-height: 27rem;
    max-height: 45rem;
    border: .5px solid lightgrey;
    cursor: pointer;
    border-radius: .7rem;
    position: relative;
    overflow: hidden;
    color: rgba(231, 220, 186, 0.5);

    &::before {
        content: "";
        display: block;
        background: linear-gradient(to bottom right, transparent, transparent);
        width: 20%;
        height: 14%;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 120;
        border-radius: 50%;
        transform: translate(50%, 50%) scale(1);
        transform-origin: center;
        transition: background .4s, transform .4s ease;
    }

    //OTI GHNETAI STO HOVER
    //einai gia to panel toy hover
    &-hover {
        opacity: 0;
        transition-delay: .4s;
        transition: opacity .2s ease;
    }


    &:hover::before {
        transform: translate(50%, 50%) scale(19);
        background: linear-gradient(to bottom right, #521e1e, rgba(90, 90, 90, 0.8));
    }

    &:hover &-hover {
        opacity: 1;
    }


    &-prosfora {
        position: absolute;
        left: -14%;
        top: 8%;
        transform: scale(0.7);
    }

    &-new {
        position: absolute;
        right: -1%;
        top: 15%;
    }

    &-prosforaText {
        position: absolute;
        right: -1%;
        top: 60%;
    }

    &-hot {
        position: absolute;
        left: -1%;
        top: 60%;
    }

    &-main {
        height: 70%;
        display: flex;
        justify-content: center;
    }

    &-details {
        height: 30%;
    }
}