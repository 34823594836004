@mixin iconStyle {
    width: 3.5rem;
    height: 3.5rem;
    fill: #ccc;

    transition: fill .2s ease;

    @media only screen and (max-width:1400px) {
        width: 2.5rem;
        height: 2.5rem;
    }
    @media only screen and (max-width:800px) {
        width: 2rem;
        height: 2rem;
    }
}

.followUsButton {
    display: inline-block;
    cursor: pointer;
    background-color: dimgrey;
    width: 6rem;
    height: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: background-color .2s ease;

    &:hover {
        background-color: #ccc;

        .followUsButton {

            &-facebook {
                fill: #3b5998;
            }

            &-instagram {
                fill: #e95950;
            }

            &-youtube {
                fill: #ff0000;
            }

            &-email {
                fill: #dd4b39;
            }
        }

    }

    &-facebook,
    &-instagram,
    &-youtube,
    &-email {
        @include iconStyle();
    }


    @media only screen and (max-width:1400px) {
        width: 5rem;
        height: 5rem;
    }

    @media only screen and (max-width:800px) {
        width: 4rem;
        height: 4rem;
    }

    @media only screen and (max-width:350px) {
        background-color: transparent;
        border: 1px solid #ccc;
    }
}