.notification {


    &-text {
        width: 80%;
        font-size: 2rem;
        letter-spacing: 1.5px;
        text-align: center;
        user-select: none;

        @media only screen and(max-width:600px) {
            font-size: 1.7rem;
        }

        @media only screen and(max-width:450px) {
            font-size: 1.3rem;
        }
    }

    &-exit-panel {
        margin-left: 1rem;
        padding: .1rem;
        border-radius: 50%;
        border: 2px solid white;
        display: flex;
        justify-content: center;
        align-items: center;

        &-btn {

            cursor: pointer;
            // position: absolute;
            // top: 50%;
            // right: 0;
            border: none;
            width: 3rem;
            height: 3rem;
            // display: flex;
            // justify-content: center;
            // align-items: center;
            font-size: 2rem;
            color: #ffffff;
            background-color: transparent;
            // transform: translateY(-50%);

            transition: color .3s ease;

            &:hover {
                color: dimgray;
            }

            &:focus {
                outline: none;
            }

            @media only screen and (max-width:450px) {
                width: 2rem;
                height: 2rem;
                font-size: 1.6rem;
            }
        }
    }

    &-icon {
        width: 3rem;
        height: 3rem;
        fill: white;
        margin-right: 1rem;
    }
}