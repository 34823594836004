.map-panel {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 1fr max-content;

    &-map {
        background: #fbf3db;
        height: 100%;

        @media only screen and(max-width:700px) {
            min-height: 30vh;
        }
    }

    &-adress {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;

        &-title {
            font-size: 2rem;
            font-family: serif;
            color: #4d4d4d;
            letter-spacing: 2px;
            margin-right: 1rem;
        }

        &-link {
            // font-weight: 500;
            font-family: fantasy;
            font-size: 2rem;
            letter-spacing: 2px;
            color: #045404;
            text-decoration: none;
        }


        @media only screen and(max-width:900px) {

            &-title,
            &-link {
                font-size: 1.8rem;
            }
        }
    }

}