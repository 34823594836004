@mixin ArrowStyle {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 100;
    opacity: .3;
    transition: opacity .2s ease-in;

    &:hover {
        opacity: 1;
    }
}

.home-slider-control {
    position: relative;
    overflow: hidden;
    height: 60vh; //height: 90vh;
    width: 100%;

    .leftArrow {
        left: 0;
        @include ArrowStyle();
    }

    .rightArrow {
        right: 0;
        @include ArrowStyle();
    }

    .panelDots {
        z-index: 100;
        position: absolute;
        display: flex;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        padding: 1.5rem;
        opacity: .6;
        transition: opacity .2s ease-in;

        &:hover {
            opacity: 1;
        }

        @media only screen and (max-width:1200px) {
            padding: .5rem;
        }
    }

    .slider {
        position: absolute;
        top: 0%;
        left: 0%;
        height: 100%;
        width: 100%;
        background-color: white;

        transition: left 1s ease-in;

        &-1 {
            left: 100%;
        }

        &-2 {
            left: -100%;
        }

        &-3 {
            left: 0%;
        }
    }

    @media only screen and (min-height:600px) {
        height: 60vh;
    }
    @media only screen and (max-height:599px) {
        height: 70vh;
    }
}