@import '../../../sass/colors/colors.scss';

//Einai gia ta svg sto hover Top
.circleMenu {
    display: inline-flex;
    padding: .6rem;
    border-radius: 50%;
    ;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color .2s;
    // border: 1px solid $color-form-login;

    &:hover {
        background-color: $color-form-login;

        .circleMenu-logo {
            fill: $color-text-grey;
        }
    }

    &-logo {
        width: 2.5rem;
        height: 2.5rem;
        fill: $color-form-login;
        transition: fill .2s;
        &-select{
            fill: $color-google;
            stroke: white;
            stroke-width: 2;
        }
    }
}

//einai gia ta svg sto hover main
.svg {

    &-logo {
        width: 100%;
        height: 100%;
    }

    &-fill {
        fill: $color-svg-light;

        &-none {
            fill: none;
        }
    }

    &-stroke {
        stroke: $color-svg-light;
        stroke-width: 2.36;

        &-medium {
            stroke-width: 5;
        }

        &-big {
            stroke-width: 8;
        }
    }
}

//einai gia to svg tis cameras
.cameraImage {

    &-place {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    &-num {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: $color-svg-light;
        font-size: 1.5rem;
        font-weight: bold;

        &::selection {
            background-color: transparent;
        }
    }
}