.contact {
    position: relative;
    display: grid;
    grid-template-rows: repeat(9, 60px);
    grid-template-columns: repeat(8, 1fr);
    padding: 0 2rem;

    &-title {
        grid-row: 1/2;
        grid-column: 1/-1;
        text-align: center;
        font-size: 2rem;
        letter-spacing: 2px;
        color: #4d4d4d;

        h2 {
            border-bottom: 4px double currentColor;
            display: inline-block;
            padding: 1rem;
        }

        @media only screen and(max-width:900px) {
            font-size: 1.7rem;
        }

        @media only screen and(max-width:500px) {
            margin-bottom: 0.8rem;
        }
    }

    &-images {
        grid-row: 2/9;
        grid-column: 1/3;
        // background-color: firebrick;

        @media only screen and(max-width:1300px) {
            display: none;
        }
    }

    &-details {
        grid-row: 2/9;
        grid-column: 3/-1;
        // background-color: gold;

        display: grid;
        grid-template-columns: 40% 60%;

        @media only screen and(max-width:1300px) {
            grid-row: 2/-1;
            grid-column: 1/-1;
        }

        @media only screen and(max-width:700px) {
            display: flex;
            flex-direction: column;
        }

        &-text {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;

            &-orario {
                &-title {
                    font-size: 2rem;
                    color: #4d4d4d;
                    letter-spacing: 2px;
                    text-transform: capitalize;
                    padding-bottom: 0.5rem;
                    border-bottom: 1px solid currentColor;
                    display: inline-block;
                }

                @media only screen and(max-width:500px) {
                    margin-bottom: .8rem;
                }
            }

            &-phones {
                &-title {
                    font-size: 2rem;
                    color: #4d4d4d;
                    letter-spacing: 2px;
                    text-transform: capitalize;
                    padding-bottom: 0.5rem;
                    border-bottom: 1px solid currentColor;
                    display: inline-block;
                    margin-bottom: 1rem;
                }
            }

            &-mail {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: center;
                margin-top: 2rem;


                &-title {
                    font-size: 2rem;
                    color: #4d4d4d;
                    letter-spacing: 2px;
                    text-transform: capitalize;
                    padding-bottom: 0.5rem;
                    border-bottom: 1px solid currentColor;
                    display: inline-block;
                    margin-bottom: 1rem;
                }

                &-email {
                    font-weight: 500;
                    font-family: fantasy;
                    font-size: 2rem;
                    letter-spacing: 5px;
                    color: #045404;
                    text-decoration: none;


                    @media only screen and(max-width:900px) {
                        font-size: 1.8rem;
                    }
                }
            }
        }

        &-map {
            display: grid;
            grid-template-rows: max-content;
            justify-items: center;

            &-title {
                text-align: center;
                font-size: 2rem;
                color: #4d4d4d;
                letter-spacing: 2px;
                text-transform: capitalize;
                padding-bottom: 0.5rem;
                border-bottom: 1px solid currentColor;
                display: inline-block;
                margin-bottom: 2rem;
            }

        }
    }

    @media only screen and(max-width:1300px) {
        margin-bottom: 4rem;
    }

    @media only screen and(max-width:700px) {
        display: flex;
        flex-direction: column;
    }
}